import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import homeButtonIcon from "../assets/icons/House.png";
import listingsButtonIcon from "../assets/icons/ClipboardText.png";
import usersButtonIcon from "../assets/icons/Users.png";
import { RootState } from "../redux/store";
import Overlay from "../components/Overlay/Overlay";
import "./Navbar.css";
import { logoutActionCreator } from "../Login/loginSlice";

const Button = ({
  icon,
  alt,
  to,
}: {
  icon: typeof listingsButtonIcon;
  alt: string;
  to: string;
}) => {
  const location = useLocation();
  const selected = location.pathname.split("/")[1] === to.slice(1);
  const buttonClass =
    "navbarButton" + (selected ? " navbarButtonSelected" : "");
  return (
    <div className={buttonClass}>
      <Link to={to}>
        <img alt={alt} src={icon} />
      </Link>
    </div>
  );
};

const Avatar = ({ name, color }: { name: string; color: string }) => {
  return (
    <div className="avatar">
      <p className="avatarText">
        {name.split(" ").map<string>((name) => name.toUpperCase()[0])}
      </p>
    </div>
  );
};

const SmarkNavbar: () => JSX.Element = () => {
  const [showLogout, setShowLogout] = useState(false);

  const dispatch = useDispatch();

  const isSignedIn: boolean = useSelector(
    (state: RootState) => state.session.loggedIn
  );

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setShowLogout(!showLogout);
  };

  const handleLogout = (event: MouseEvent) => {
    event.stopPropagation();
    dispatch(logoutActionCreator(null));
    setShowLogout(false);
  };

  return (
    <div className="mainNavbarContainer">
      <div className="navigationContainer">
        <Button icon={homeButtonIcon} alt="Dashboard" to="/" />
        <Button icon={listingsButtonIcon} alt="Listings" to="/listings" />
        <Button icon={usersButtonIcon} alt="Page" to="/users" />
      </div>
      {isSignedIn === true && (
        <>
          <div className="profileContainer" onClick={handleClick}>
            <Avatar name="Smark Admin" color="blue" />
          </div>
          {showLogout === true && (
            <Overlay
              title="Welcome, skillin' admin!"
              onConfirm={handleLogout}
              confirmText="Logout"
              onCancel={() => setShowLogout(false)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SmarkNavbar;
