import React, { HTMLProps, InputHTMLAttributes, useMemo } from "react";
import PageHeader from "../Header/Header";
import "./Page.css";
import "../components/Table/Table.css";

export default function Page(props: HTMLProps<any> & { title: string }) {
  return (
    <div className="page">
      <PageHeader title={props.title} />
      <div className="contentScrollContainer">
        <div className="contentPadding">{props.children}</div>
      </div>
    </div>
  );
}
