import { Switch } from "react-router-dom";
import RouteWrapper from "./RouteWrapper";
import Listings from "../Listings/Listings";
import Users from "../Users/Users";
import Dashboard from "../Dashboard/Dashboard";
import Login from "../Login/Login";

function Routes() {
  return (
    <Switch>
      <RouteWrapper
        path="/listings/:teacherName"
        component={Listings}
        isPrivate
      />
      <RouteWrapper path="/listings/" component={Listings} isPrivate />
      <RouteWrapper path="/users/:userName" component={Users} isPrivate />
      <RouteWrapper path="/users" component={Users} isPrivate />
      <RouteWrapper path="/login" component={Login} />
      <RouteWrapper path="/" component={Dashboard} isPrivate />
    </Switch>
  );
}

export default Routes;
