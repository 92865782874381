import { useDispatch, useSelector } from "react-redux";
import { RouteProps, Redirect, Route } from "react-router-dom";
import { RootState } from "../redux/store";
import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { logoutActionCreator } from "../Login/loginSlice";

//custom interface to include our prop to hide authenticated routes
//RouteProps already contains path and component
interface RouteWrapperProps extends RouteProps {
  isPrivate?: boolean;
}

function RouteWrapper({
  component: Component,
  isPrivate,
  path,
  ...rest
}: RouteWrapperProps): JSX.Element {
  const dispatch = useDispatch();

  const isSignedIn: boolean = useSelector(
    (state: RootState) => state.session.loggedIn
  );

  useEffect(() => {
    //checks that a users session is still active, if not log them out
    const checkAuth = async () => {
      try {
        await Auth.currentAuthenticatedUser();
      } catch (error) {
        console.log(error);
        dispatch(logoutActionCreator(null));
      }
    };
    checkAuth();
  }, []);

  //if not logged in and the route is private, hide the route
  if (isPrivate && !isSignedIn) {
    return <Redirect to="/login" />;
  }

  //if user successfully logs in while on the login page, reroute to home
  if (isSignedIn && path === "/login") {
    return <Redirect to="/" />;
  }

  //default
  return <Route {...rest} component={Component} />;
}

export default RouteWrapper;
