import { AxiosResponse } from "axios";
import client from "./client";

const endpoint: string = "/users";

interface ApiVersion {
  [methodName: string]: (...props: any) => Promise<AxiosResponse>;
}

const v1: ApiVersion = {
  getDashboardData: async () => client.get(`${endpoint}/adminDashboard`),
  getAllUsers: async () => client.get(endpoint),
  blockUser: async (userId: string) =>
    client.post(
      `${endpoint}/${userId}/block`,
      null,
    ),
};

export const getAllUsers: () => Promise<any> = async () => {
  try {
    const { data } = await v1.getAllUsers();
    return data.with;
  } catch (error) {
    throw error;
  }
};

export const blockUser: (userId: string) => Promise<any> = async (
  userId
) => {
  try {
    const { data } = await v1.blockUser(userId);
    return data.with;
  } catch (error) {
    throw error;
  }
};

export const getDashboardData: () => Promise<any> = async () => {
  try {
    const {data} = await v1.getDashboardData();
    return data.with;
  } catch (error) {
    throw error;
  }
}
