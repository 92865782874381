import {defaults} from 'lodash';

export class Listing {
  allowGroups!: boolean;
  approxTime!: string;
  availability!: [];
  avgRatingForListing!: number;
  created!: string;
  description!: string;
  geoCoords!: [];
  guid!: string;
  images!: [];
  isBlocked!: boolean;
  isPaused!: boolean;
  keywords!: [];
  lessonsCount!: number;
  locations!: [];
  passionInfo!: string;
  passionId!: string;
  rate!: string;
  rateType!: string;
  supplies!: string;
  title!: string;
  userId!: string;
  userName!: string;

  constructor(properties: any) {
    defaults(this, {
      allowGroups: properties['allowGroups'],
      approxTime: properties['approxTime'],
      availability: properties['availability'],
      avgRatingForListing: parseFloat(properties['avgRatingForListing'] || 0).toFixed(1),
      created: properties['created'],
      description: properties['description'],
      geoCoords: properties['geoCoords'],
      guid: properties['guid'],
      images: properties['images'],
      isBlocked: properties['isBlocked'] || false,
      isPaused: properties['isPaused'] || false,
      keywords: properties['keywords'],
      lessonsCount: properties['lessonsCount'],
      locations: properties['locations'],
      passionInfo: properties['passionInfo'],
      passionId: properties['passionId'],
      rate: properties['rate'],
      rateType: properties['rateType'],
      supplies: properties['supplies'],
      title: properties['title'],
      userId: properties['userId'],
      userName: properties['userName'],
    })
  }
}
