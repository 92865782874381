import { AxiosResponse } from "axios";
import client from "./client";

const endpoint: string = "/listings";

interface ApiVersion {
  [methodName: string]: (...props: any) => Promise<AxiosResponse>;
}

const v1: ApiVersion = {
  getAllListings: async () => client.get(endpoint),
  blockListing: async (listingId: string) => client.post(
      `${endpoint}/${listingId}/block`,
      null,
    ),
};

export const getAllListings: () => Promise<any> = async () => {
  try {
    const { data } = await v1.getAllListings();
    return data.with;
  } catch (error) {
    throw error;
  }
};

export const blockListing: (listingId: string) => Promise<any> = async (
  listingId
) => {
  try {
    const { data } = await v1.blockListing(listingId);
    return data.with;
  } catch (error) {
    throw error;
  }
};
