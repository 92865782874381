import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React from "react";

export interface IGraphDataPoint<X> {
  label: X;
  data: number;
}

export default function SmoothGraph({
  data,
}: {
  data: Array<IGraphDataPoint<string>>;
}) {
  return (
    <ResponsiveContainer width={"100%"} height={514}>
      <LineChart
        data={data}
        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
      >
        <YAxis yAxisId={0} />
        <XAxis dataKey="label" minTickGap={20} />
        <Tooltip />
        <CartesianGrid stroke="#f5f5f5" />
        <Line
          type="monotone"
          dataKey="data"
          stroke="#414288"
          strokeWidth={2}
          yAxisId={0}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
