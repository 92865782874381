import { combineReducers, configureStore, EnhancedStore } from "@reduxjs/toolkit";
import { Reducer } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { PersistConfig, Persistor } from "redux-persist/es/types";
import storage from 'redux-persist/lib/storage';
import loginReducer from "../Login/loginSlice";

const rootReducer: Reducer = combineReducers({
  session: loginReducer,
});

const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage,
}

const store: EnhancedStore = configureStore({
  reducer: persistReducer(persistConfig, rootReducer)
});

export default store;

export const persistor: Persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
