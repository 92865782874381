import { AxiosRequestConfig } from "axios";
import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import get from "lodash/get";

//can be used on individual requests or on all requests via axios.interceptors
const makeAuthenticatedAxiosOptions: () => Promise<AxiosRequestConfig> =
  async () => {
    const authResponse: Promise<CognitoUser | any> = await Auth.currentAuthenticatedUser();
    const cognitoToken: string = get(
      authResponse,
      "signInUserSession.accessToken.jwtToken"
    );

    const requestOptions: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cognitoToken}`,
      },
    };

    return requestOptions;
  };

export default makeAuthenticatedAxiosOptions;
