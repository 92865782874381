import { MouseEvent, useState } from "react";
import { Button, Card } from "react-bootstrap";
import "./Overlay.css";

const Overlay = ({
  onCancel,
  onConfirm,
  title = "",
  confirmText = "Confirm",
  cancelText = "Cancel",
}: any) => {
  const [disableConfirm, setDisableConfirm] = useState<boolean>(false);

  const handleConfirm = async (e: MouseEvent) => {
    setDisableConfirm(true);
    await onConfirm(e);
  };

  return (
    <div className="overlayScreenCover" onClick={onCancel}>
      <Card
        className="text-center"
        onClick={(event: MouseEvent) => event.stopPropagation()}
      >
        <Card.Header as="h5">{title}</Card.Header>
        <Card.Body className="overlayContainer">
          <Button
            style={{ marginRight: 10 }}
            variant="primary"
            onClick={handleConfirm}
            disabled={disableConfirm}
          >
            {confirmText}
          </Button>
          <Button variant="secondary" onClick={onCancel}>
            {cancelText}
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Overlay;
