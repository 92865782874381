import axios, { AxiosInstance } from "axios";
import makeAuthenticatedAxiosOptions from "../utils/makeAuthenticatedAxiosOptions";

const client: AxiosInstance = axios.create({
  baseURL: "https://api.getskillin.com/v1",
  // baseURL: "http://localhost:3003/v1",
});

client.interceptors.request.use(async (config) => {
  const options = await makeAuthenticatedAxiosOptions();
  config.headers = { ...options.headers };
  return config;
});

export default client;
