import { HTMLProps, useEffect, useState } from "react";
import { Bounce } from "react-activity";
import "recharts";
import Page from "../Page/Page";
import { WhiteRoundedContainer } from "../components/WhiteRoundedContainer/WhiteRoundedContainer";
import SmoothGraph from "../components/SmoothGraph";
import { GraphDataPoint } from "../models/GraphDataPoint";
import { getDashboardData } from "../api/users";
import UsersIcon from "../assets/icons/Users.png";
import ClipboardIcon from "../assets/icons/ClipboardText.png";
import ClockIcon from "../assets/icons/Clock.png";
import "./Dashboard.css";

function IconContainer({ icon }: { icon: typeof UsersIcon }) {
  return (
    <div className="iconContainer">
      <img src={icon} alt="userIcon" />
    </div>
  );
}

function InfoBox(
  props: HTMLProps<any> & { icon: typeof UsersIcon; title: string }
) {
  return (
    <WhiteRoundedContainer>
      <div className="infoBoxContainer">
        <div className="infoBoxHeaderRow">
          <IconContainer icon={props.icon} />
          <p className="infoBoxHeaderTitle">{props.title}</p>
        </div>
        {props.children}
      </div>
    </WhiteRoundedContainer>
  );
}

function InfoColumn(props: { header: string; body: string; footer: string }) {
  return (
    <div className="infoColumnContainer">
      <p className="infoColumnHeader">{props.header}</p>
      {props.body ? (
        <p
          className="infoColumnBody"
          style={props.header ? { marginTop: "10px" } : { marginTop: "25px" }}
        >
          {props.body}
        </p>
      ) : (
        <div className="bounceContainer">
          <Bounce size={50} color="#414288" />
        </div>
      )}
      <p
        className="infoColumnFooter"
        style={props.header ? { marginTop: "15px" } : { marginTop: "30px" }}
      >
        {props.footer}
      </p>
    </div>
  );
}

export default function Dashboard() {
  const [data, setData] = useState<any>();
  const [usersGraphData, setUsersGraphData] = useState<Array<any>>([]);
  const [listingsGraphData, setListingsGraphData] = useState<Array<any>>([]);

  useEffect(() => {
    const getAndSetData = async () => {
      try {
        const res = await getDashboardData();
        setData(res);
        setUsersGraphData(convertGraphData(res.usersGraphData));
        setListingsGraphData(convertGraphData(res.listingsGraphData));
      } catch (error) {
        console.log(error);
        alert("Could not get dashboard data");
      }
    };
    getAndSetData();
  }, []);

  function convertGraphData(
    inputData: { label: string; data: number }[]
  ): GraphDataPoint[] {
    let temp: { label: string; data: number }[] = [];

    inputData.forEach((input: any) => {
      temp.push({
        label: input.label,
        data: input.data + (temp[temp.length - 1]?.data || 0),
      });
    });

    return temp.map(
      (input: { label: string; data: number }) => new GraphDataPoint(input)
    );
  }

  return (
    <Page title="Dashboard">
      <div className="dashboardRows">
        <div className="dashboardTopColumns">
          <InfoBox icon={UsersIcon} title="Total Users">
            <InfoColumn header="" body={data?.totalUsers} footer="" />
          </InfoBox>
          <div style={{ width: 85 }} />
          <InfoBox icon={ClockIcon} title="Past 30 Days">
            <div className="secondBoxInfoColumnRow">
              <InfoColumn
                header="NEW USERS"
                body={data?.past30NewUsers?.toString()}
                footer=""
              />
              <div className="secondBoxInfoColumnDivider" />
              <InfoColumn
                header="LESSONS TAUGHT"
                body={data?.past30LessonsTaught?.toString()}
                footer=""
              />
              <div className="secondBoxInfoColumnDivider" />
              <InfoColumn
                header="NEW TEACHERS"
                body={data?.past30NewTeachers?.toString()}
                footer=""
              />
              <div className="secondBoxInfoColumnDivider" />
              <InfoColumn
                header="AVG. LESSON RATING"
                body={
                  data?.past30AvgLessonRating?.toString() &&
                  parseFloat(data?.past30AvgLessonRating).toFixed(1)
                }
                footer=""
              />
            </div>
          </InfoBox>
        </div>
        <div style={{ height: 75 }} />
        <div className="dashboardBottomColumns">
          <div className="bottomColumnInfoBoxContainer">
            <InfoBox icon={UsersIcon} title="Cumulative User Count">
              <div className="graphContainer">
                <SmoothGraph data={usersGraphData} />
              </div>
            </InfoBox>
          </div>
          <div style={{ width: 45 }} />
          <div className="bottomColumnInfoBoxContainer">
            <InfoBox icon={ClipboardIcon} title="Cumulative Listing Count">
              <div className="graphContainer">
                <SmoothGraph data={listingsGraphData} />
              </div>
            </InfoBox>
          </div>
        </div>
        <div style={{ height: 100 }} />
      </div>
    </Page>
  );
}
