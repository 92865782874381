import { useEffect, useMemo, useState } from "react";
import "./Listings.css";
import "../components/Table/Table.css";
import {
  cellRenderer,
  RFTable,
  RFDropdownHeader,
  RFTextInputHeader,
  RFNoInputHeader,
} from "../components/Table/Table";
import Page from "../Page/Page";
import { WhiteRoundedContainer } from "../components/WhiteRoundedContainer/WhiteRoundedContainer";
import { getAllListings, blockListing } from "../api/listings";
import { Listing } from "../models/Listing";
import { useParams } from "react-router-dom";

export default function Listings() {
  const [listings, setListings] = useState<Array<Listing>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const params = useParams<{ teacherName?: string }>();

  const teacherId = params["teacherName"] || "";

  const getAndSetListings = async () => {
    try {
      setLoading(true);
      let res = await getAllListings();
      if (teacherId) {
        res = res.filter(
          ({ userId }: { userId: string }) => userId === teacherId
        );
      }
      setListings(
        res
          .sort(
            (a: any, b: any) =>
              new Date(b.created).getTime() - new Date(a.created).getTime()
          )
          .map((listing: any) => new Listing(listing))
      );
      setLoading(false);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    getAndSetListings();
  }, [teacherId]);

  //updates data without refetching
  function updateMyData(
    rowIndex: number,
    columnId: string,
    value: string
  ): void {
    const temp = [...listings];
    const newData = temp.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...temp[rowIndex],
          [columnId]: value,
        };
      }
      return row;
    });
    setListings(newData);
  }

  const columns = useMemo(
    () =>
      [
        new RFTextInputHeader("title", cellRenderer.LinkToListing, "TITLE"),
        new RFTextInputHeader("userName", cellRenderer.LinkToUsers, "NAME"),
        new RFTextInputHeader("passionInfo", cellRenderer.passion, "PASSION"),
        new RFTextInputHeader(
          "keywords",
          cellRenderer.tags,
          "LISTING TAGS",
          false,
          true
        ),
        new RFNoInputHeader("created", cellRenderer.date, "DATE POSTED"),
        new RFNoInputHeader("lessonsCount", cellRenderer.number, "TIMES TAKEN"),
        new RFNoInputHeader(
          "avgRatingForListing",
          cellRenderer.number,
          "RATING"
        ),
        new RFDropdownHeader("isPaused", cellRenderer.paused, "All", "PAUSED"),
        new RFDropdownHeader(
          "isBlocked",
          cellRenderer.Blocked(async (row) => {
            try {
              const newStatus = await blockListing(row.original.guid);
              updateMyData(parseInt(row.id, 10), "isBlocked", newStatus);
            } catch (error) {
              alert(error);
            }
          }),
          "All",
          "BLOCKED"
        ),
      ].map((header) => header.column()),
    [listings]
  );

  return (
    <Page title="Listings">
      <WhiteRoundedContainer>
        <RFTable
          columns={columns}
          data={listings}
          updateMyData={updateMyData}
          loading={loading}
        />
      </WhiteRoundedContainer>
    </Page>
  );
}
