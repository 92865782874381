import {defaults} from 'lodash';

export class User {
    created!: Date;
    email!: string;
    fullName!: string;
    homeCity!: string;
    isBlocked!: boolean;
    lessonsTaught!: number;
    lessonsTaken!: number;
    listingCount!: number;
    overallRating!: number;
    sub!: string;

    constructor(properties:any) {

        defaults(this, {
            created: properties["created"],
            email: properties["email"],
            fullName: properties["fullName"],
            homeCity: properties["homeCity"],
            isBlocked: properties["isBlocked"] || false,
            lessonsTaught: properties["lessonsTaught"] || 0,
            lessonsTaken: properties["lessonsTaken"] || 0,
            listingCount: properties["listingCount"] || 0,
            overallRating: parseFloat(properties["overallRating"] || 0).toFixed(1),
            sub: properties["sub"],
        })
    }
}
