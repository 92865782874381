import { BaseSyntheticEvent, useEffect, useState } from "react";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";
import dayjs from "dayjs";
import BlueProhibit from "../../assets/icons/BlueProhibit.png";
import Prohibit from "../../assets/icons/Prohibit.png";
import ArrowUp from "../../assets/icons/arrowUp.png";
import ArrowDown from "../../assets/icons/arrowDown.png";
import { useHistory } from "react-router-dom";
import { Bounce } from "react-activity";
import Overlay from "../Overlay/Overlay";

export const cellRenderer = {
  underlinedText: ({ value }: { value: string }) => (
    <div className="entryContainer entryTextUnderline entryText" title={value}>
      {value}
    </div>
  ),
  LinkToEmail: ({ value }: { value: string }) => (
    <div className="entryContainer entryTextUnderline entryText">
      <a
        className="entryTextUnderline entryText"
        title={`${value}\r\nClick To Open An Email`}
        href={`mailto:${value}`}
      >
        {value}
      </a>
    </div>
  ),
  LinkToListing: ({ value, row }: { value: string; row: any }) => {
    return (
      <div className="entryContainer entryTextUnderline entryText">
        <a
          className="entryTextUnderline entryText"
          title={`${value}\r\nClick To Open Listing In New Tab`}
          href={`http://listings.getskillin.com/#/${row.original.guid}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {value}
        </a>
      </div>
    );
  },
  passion: ({ value }: { value: string }) => (
    <div className="entryText passionCell" title={value}>
      {value}
    </div>
  ),
  number: ({ value }: { value: number }) => (
    <div className="entryText entryTextCenter" title={value.toString()}>
      {value}
    </div>
  ),
  location: ({ value }: { value: string }) => (
    <div className="entryContainer entryText locationCell" title={value}>
      {value}
    </div>
  ),
  LinkToListings: ({ value, row }: { value: string; row: any }) => {
    const history = useHistory();
    return (
      <div
        className="entryContainer entryText entryTextUnderline nameCell"
        title={`${value}\r\nClick To See Their Listings`}
        onClick={() => history.push(`/listings/${row.original.sub}`)}
      >
        {value}
      </div>
    );
  },
  LinkToUsers: ({ value, row }: { value: string; row: any }) => {
    const history = useHistory();
    return (
      <div
        className="entryContainer entryText entryTextUnderline nameCell"
        title={`${value}\r\nClick To See In Users Table`}
        onClick={() => history.push(`/users/${row.original.userId}`)}
      >
        {value}
      </div>
    );
  },
  date: ({ value }: { value: Date }) => (
    <div className="entryText entryTextCenter">
      {dayjs(value).format("MM/DD/YYYY")}
    </div>
  ),
  tags: ({ value, row }: { value: Array<string>; row: any }) => (
    <div className="tagContainer">
      <div className="tagHolder">
        {value.map((tag) => (
          <div className="tagBox" key={"tag:" + tag + "forRow:" + row.id}>
            <p className="tagText">{tag}</p>
          </div>
        ))}
      </div>
    </div>
  ),
  paused: ({ value }: { value: boolean | undefined }) => (
    <div className="entryText entryTextCenter">{value ? "Yes" : "No"}</div>
  ),
  Blocked:
    (handleBlock: (row: any) => void) =>
    ({ value, row }: { value: boolean; row: any }) => {
      return (
        <BlockComponent row={row} value={value} handleBlock={handleBlock} />
      );
    },
};

const BlockComponent = ({ value, row, handleBlock }: any) => {
  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  const onConfirm = async () => {
    await handleBlock(row);
    setShowOverlay(false);
  };

  const title = `Are you sure you want to ${value ? "un" : ""}block ${
    row.original.fullName || row.original.title
  }?`;

  return (
    <>
      <div
        className="entryText entryTextCenter"
        onClick={() => setShowOverlay(true)}
      >
        <img
          className="isBlockedImage"
          src={value ? BlueProhibit : Prohibit}
          alt="blockedImage"
        />
      </div>
      {showOverlay === true && (
        <Overlay
          title={title}
          onConfirm={onConfirm}
          onCancel={() => setShowOverlay(false)}
        />
      )}
    </>
  );
};

export interface DropDownOption {
  label: string;
  value: string | undefined;
}

export const yesNoOptions: DropDownOption[] = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];
interface SortFunc {
  (columnId: string, desc: boolean, multi: boolean): void;
}

function SortOptions({
  sortUp,
  sortDown,
  unSort,
  isSortedDesc,
}: {
  sortUp?: any;
  sortDown?: any;
  unSort?: any;
  isSortedDesc?: boolean | undefined;
}) {
  const sortedDescClass: string =
    isSortedDesc === true ? "sortOption selectedSortOption" : "sortOption";
  const sortedAscClass: string =
    isSortedDesc === false ? "sortOption selectedSortOption" : "sortOption";
  return (
    <div className="sortRow noselect">
      <div
        className={sortedAscClass}
        onClick={() => {
          if (isSortedDesc === false) {
            unSort();
          } else {
            sortUp();
          }
        }}
      >
        <img src={ArrowUp} alt="sortUp" height={35} width={35} />
      </div>
      <div
        className={sortedDescClass}
        onClick={() => {
          if (isSortedDesc === true) {
            unSort();
          } else {
            sortDown();
          }
        }}
      >
        <img src={ArrowDown} alt="sortDown" height={35} width={35} />
      </div>
    </div>
  );
}

class RFHeader {
  accessor: string;
  cellRenderer: (props: any) => JSX.Element;
  constructor(accessor: string, cellRenderer: (props: any) => JSX.Element) {
    this.accessor = accessor;
    this.cellRenderer = cellRenderer;
  }

  column(): any {
    return {};
  }
}

export class RFDropdownHeader extends RFHeader {
  placeholder: string;
  title: string;
  options: DropDownOption[];
  constructor(
    accessor: string,
    cellRenderer: (props: any) => JSX.Element,
    placeholder: string,
    title: string,
    options?: Array<any>
  ) {
    super(accessor, cellRenderer);
    this.placeholder = placeholder;
    this.title = title;
    this.options = yesNoOptions;
  }

  column(): any {
    const header = this;
    return {
      accessor: header.accessor,
      disableSortBy: true,
      canFilter: true,
      Cell: header.cellRenderer,
      Header: ({
        setFilter,
      }: {
        filterValue: any;
        setFilter: (id: string, filterValue: any) => void;
      }) => {
        const handleFilterChange = (e: BaseSyntheticEvent) => {
          const value = e.target.value || undefined;
          setFilter(header.accessor, value);
        };

        return (
          <div className="columnHeaderContainer">
            <div className="colHeaderFormContainer colHeaderFormContainerCentered dropdownColHeaderFormContainer">
              <form name="title" className="colHeaderForm">
                <div className="colHeaderCol">
                  <label className="dropdownColHeaderText">
                    {header.title}
                  </label>
                  <select
                    onChange={handleFilterChange}
                    placeholder="Search"
                    className="dropdown inputText"
                    defaultValue=""
                  >
                    <option className="dropdownDefaultOptionText" value="">
                      {header.placeholder}
                    </option>
                    {header.options.map((option) => (
                      <option
                        className="dropdownOptionText"
                        value={option.value}
                        key={header.accessor + "dropDownOption" + option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </form>
            </div>
          </div>
        );
      },
    };
  }
}

export class RFTextInputHeader extends RFHeader {
  title: string;
  placeholder: string;
  center: boolean;
  disableSortBy: boolean;
  initialFilter?: string;
  constructor(
    accessor: string,
    cellRenderer: (props: any) => JSX.Element,
    title: string,
    center: boolean = false,
    disableSortBy: boolean = false,
    placeholder: string = "Search",
    initialFilter: string = ""
  ) {
    super(accessor, cellRenderer);
    this.title = title;
    this.placeholder = placeholder;
    this.center = center;
    this.disableSortBy = disableSortBy;
    this.initialFilter = initialFilter;
  }

  // we have to use any types because the react-table types are actual garbage nonsense
  column(): any {
    const header = this;
    return {
      accessor: header.accessor,
      canFilter: true,
      disableSortBy: header.disableSortBy,
      Cell: header.cellRenderer,
      Header: ({
        filterValue,
        setFilter,
        column: { clearSortBy, isSortedDesc },
        toggleSortBy,
      }: {
        filterValue: any;
        setFilter: (id: string, filterValue: any) => void;
        column: any;
        toggleSortBy: SortFunc;
      }) => {
        useEffect(() => {
          setFilter(header.accessor, header.initialFilter);
        }, [header.initialFilter]);

        const handleFilterChange = (e: BaseSyntheticEvent) => {
          const value = e.target.value || undefined;
          setFilter(header.accessor, value);
        };

        return (
          <div className="columnHeaderContainer">
            <div
              className={
                "colHeaderFormContainer" +
                (!header.center
                  ? " colHeaderFormContainerPadded"
                  : " colHeaderFormContainerCentered")
              }
            >
              <form name={header.accessor} className="colHeaderForm">
                <div className="colHeaderCol">
                  <div className="colHeaderTextWithSort">
                    <label className="textInputColHeaderText">
                      {header.title}
                    </label>
                    {!this.disableSortBy && (
                      <SortOptions
                        sortUp={() =>
                          toggleSortBy(header.accessor, false, false)
                        }
                        sortDown={() =>
                          toggleSortBy(header.accessor, true, false)
                        }
                        unSort={() => clearSortBy()}
                        isSortedDesc={isSortedDesc}
                      />
                    )}
                  </div>
                  <input
                    onChange={handleFilterChange}
                    placeholder="Search"
                    defaultValue={header.initialFilter}
                  />
                </div>
              </form>
            </div>
          </div>
        );
      },
    };
  }
}

export class RFNoInputHeader extends RFHeader {
  title: string;
  constructor(
    accessor: string,
    cellRenderer: (props: any) => JSX.Element,
    title: string
  ) {
    super(accessor, cellRenderer);
    this.title = title;
  }

  // we have to use any types because the react-table types are actual garbage nonsense
  column(): any {
    const header = this;
    return {
      accessor: header.accessor,
      canFilter: true,
      Cell: header.cellRenderer,
      Header: ({
        toggleSortBy,
        column: { clearSortBy, isSortedDesc },
      }: {
        toggleSortBy: SortFunc;
        column: any;
      }) => {
        return (
          <div className="columnHeaderContainer">
            <div className="colHeaderFormContainer">
              <form name={header.title} className="colHeaderForm">
                <div className="colHeaderCol">
                  <label className="textInputColHeaderText">
                    {header.title}
                  </label>
                </div>
                <SortOptions
                  sortUp={() => toggleSortBy(header.accessor, false, false)}
                  sortDown={() => toggleSortBy(header.accessor, true, false)}
                  unSort={() => clearSortBy()}
                  isSortedDesc={isSortedDesc}
                />
              </form>
            </div>
          </div>
        );
      },
    };
  }
}

export function RFTable({ columns, data, updateMyData, loading }: any) {
  const initialState: any = {
    pageIndex: 0,
    pageSize: 10,
  };

  const options: any = {
    columns,
    data,
    autoResetPage: false,
    initialState: initialState,
    updateMyData,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    nextPage,
    previousPage,
    gotoPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    page,
  }: any = useTable(options, useFilters, useSortBy, usePagination);

  return (
    <div className="listScrollContainer">
      <table {...getTableProps()} className="tableRows">
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="tableHeader">
              {headerGroup.headers.map((column: any) => {
                return (
                  <th {...column.getHeaderProps()} className="columnHeader">
                    {column.render("Header")}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {loading ? (
          <div className="bounceContainer">
            <Bounce size={50} color="#414288" />
          </div>
        ) : (
          <tbody {...getTableBodyProps()}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      <div>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>
      </div>
    </div>
  );
}
