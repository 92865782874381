import { useState } from "react";
import { useDispatch } from "react-redux";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { loginActionCreator } from "./loginSlice";
import { Form, Button, Card, Image } from "react-bootstrap";
import skillinLogo from "../assets/skillinLogo.png";
import "./Login.css";

const Login = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const dispatch = useDispatch();

  const authorizeUser = async (user: string, pass: string) => {
    try {
      await Auth.signIn(user, pass);
      getAndSetUser();
    } catch (error) {
      alert(error.message);
    }
  };

  const getAndSetUser = async () => {
    try {
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      const payload = await user.getSignInUserSession()?.getIdToken().payload;
      if (payload?.["cognito:groups"]?.includes("admin")) {
        console.log("this user is an admin");
        dispatch(loginActionCreator(payload));
      } else {
        throw Error("That account is not authorized");
      }
    } catch (err) {
      alert(JSON.stringify(err.message || err));
    }
  };

  return (
    <div id="loginPage">
      <Card id="loginBody" className="text-center">
        <Card.Header as="h5">Welcome Admin!</Card.Header>
        <Card.Body>
          <Image src={skillinLogo} className="loginLogo" />
          <Form>
            <Form.Group className="loginFormGroup" controlId="formBasicEmail">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your admin username"
                value={username}
                onChange={({ target: { value } }) => setUsername(value)}
              />
            </Form.Group>
            <Form.Group
              className="loginFormGroup"
              controlId="formBasicPassword"
            >
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={({ target: { value } }) => setPassword(value)}
              />
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              onClick={(event) => {
                event.preventDefault();
                authorizeUser(username, password);
              }}
            >
              Login
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Login;
