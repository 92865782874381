import { createSlice, Slice } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";

const initialState = {
  loggedIn: false,
  user: null,
};

const loginSlice: Slice = createSlice({
  name: "login",
  initialState,
  reducers: {
    login: (state, { payload }) => {
      state.loggedIn = true;
      state.user = payload;
    },
    logout: (state) => {
      Auth.signOut();
      state.loggedIn = initialState.loggedIn;
      state.user = initialState.user;
    },
  },
});

export default loginSlice.reducer;

export const {
  login: loginActionCreator,
  logout: logoutActionCreator
} = loginSlice.actions;
