import dayjs from 'dayjs'

export class GraphDataPoint {
  data!: number;
  label!: string;

  constructor({label, data}: {label: string, data: number}) {
    this.data = data;
    this.label = dayjs(label).format("MMM YYYY")
  }
}
