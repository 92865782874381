import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  cellRenderer,
  RFTable,
  RFDropdownHeader,
  RFNoInputHeader,
  RFTextInputHeader,
} from "../components/Table/Table";
import Page from "../Page/Page";
import { WhiteRoundedContainer } from "../components/WhiteRoundedContainer/WhiteRoundedContainer";
import { blockUser, getAllUsers } from "../api/users";
import { User } from "../models/User";
import "../components/Table/Table.css";

export default function Users() {
  const [users, setUsers] = useState<Array<User>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const params = useParams<{ userName?: string }>();

  const userId = params["userName"] || "";

  const getAndSetUsers = async () => {
    try {
      setLoading(true);
      let res = await getAllUsers();
      if (userId) {
        res = res.filter(({ sub }: { sub: string }) => sub === userId);
      }
      setUsers(
        res
          .sort(
            (a: any, b: any) =>
              new Date(b.created).getTime() - new Date(a.created).getTime()
          )
          .map((document: any) => new User(document))
      );
      setLoading(false);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    getAndSetUsers();
  }, [userId]);

  //updates data without refetching
  function updateMyData(
    rowIndex: number,
    columnId: string,
    value: string
  ): void {
    const temp = [...users];
    const newData = temp.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...temp[rowIndex],
          [columnId]: value,
        };
      }
      return row;
    });
    setUsers(newData);
  }

  const columns = useMemo(
    () =>
      [
        new RFTextInputHeader("email", cellRenderer.LinkToEmail, "EMAIL"),
        new RFTextInputHeader("fullName", cellRenderer.LinkToListings, "NAME"),
        new RFTextInputHeader("homeCity", cellRenderer.location, "LOCATION"),
        new RFNoInputHeader("created", cellRenderer.date, "MEMBER SINCE"),
        new RFNoInputHeader(
          "listingCount",
          cellRenderer.number,
          "ACTIVE LISTINGS"
        ),
        new RFNoInputHeader(
          "lessonsTaught",
          cellRenderer.number,
          "LESSONS TAUGHT"
        ),
        new RFNoInputHeader(
          "lessonsTaken",
          cellRenderer.number,
          "LESSONS TAKEN"
        ),
        new RFNoInputHeader(
          "overallRating",
          cellRenderer.number,
          "TEACHER RATING"
        ),
        new RFDropdownHeader(
          "isBlocked",
          cellRenderer.Blocked(async (row) => {
            try {
              const newStatus = await blockUser(row.original.sub);
              updateMyData(parseInt(row.id, 10), "isBlocked", newStatus);
            } catch (error) {
              alert(error);
            }
          }),
          "All",
          "BLOCKED"
        ),
      ].map((header) => header.column()),
    [users]
  );

  return (
    <Page title="Users">
      <WhiteRoundedContainer>
        <RFTable
          columns={columns}
          data={users}
          updateMyData={updateMyData}
          loading={loading}
        />
      </WhiteRoundedContainer>
    </Page>
  );
}
