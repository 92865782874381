import React from "react";
import SkillinLogo from "../assets/skillinLogo.png";
import "./Header.css";

export default function PageHeader({ title }: { title: string }) {
  return (
    <div className="headerContainer">
      <div className="headerRow">
        <p className="headerText">{title}</p>
        <div className="headerImgContainer">
          <img src={SkillinLogo} alt="skillinLogo" className="headerImg" />
        </div>
      </div>
    </div>
  );
}
