import { HashRouter as Router } from "react-router-dom";
import Amplify from "aws-amplify";
import "./App.css";
import Navbar from "./Navbar/Navbar";
import Routes from "./Routes";
import "react-activity/dist/Bounce.css";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  },
});

function App() {
  return (
    <div className="App">
      <Router basename="/">
        <div className="navbarPageWrap">
          <Navbar />
          <div className="page">
            <Routes />
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
